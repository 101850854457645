<template>
  <div class="flix-form-group">
    <div v-if="$store.state.business.unsaved.bookingMethod === 'default'">
      <div class="flix-form-group">
        <label class="flix-html-label"
          >{{ $t("settings.verify.time.title") }}
          <help>{{ $t("settings.verify.time.info") }}</help></label
        >
      </div>
      <inputSelect
        :key="data"
        :settings="{ value: data, default: data, values: values }"
        :callback="setSave"
      />
    </div>
    <div class="danger" v-else>
      <icon id="circle-alert" /> {{ $t("settings.verify.warning") }}
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      default: "2",
      data: this.getDefaultVerifyTime(),
      values: {
        "1": "1 " + this.$tc("dates.name.hours", 1),
        "2": "2 " + this.$tc("dates.name.hours", 2),
        "3": "3 " + this.$tc("dates.name.hours", 3),
        "6": "6 " + this.$tc("dates.name.hours", 6),
        "12": "12 " + this.$tc("dates.name.hours", 12),
        "18": "18 " + this.$tc("dates.name.hours", 18),
        "24": "24 " + this.$tc("dates.name.hours", 24),
        "48": "48 " + this.$tc("dates.name.hours", 48)
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getDefaultVerifyTime() {
      if (!this.$store.state.business.unsaved.verifyTime) {
        return this.default;
      }
      return this.$store.state.business.unsaved.verifyTime;
    },
    setSave(ret) {
      this.data = ret;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.verifyTime = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
